import { useTranslation } from '@/hooks/translations';
import { OrderStatusEnum, IQsrOrder } from '@/views/QsrOrders/types';
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import QsrOrdersService from '@/services/qsrOrders';
import { useSnackbar } from 'notistack';
import { PrimaryButton } from '@/components/common';
import { CheckRounded, Close } from '@mui/icons-material';
import { IRestaurant } from '@/views/OrdersTableView/types';
import QsrReasonModal, { IReasonDetail } from '@/components/QsrReasonModal';

interface IAction {
    id: string;
    color: string;
    bgColor?: string;
    secondaryBgColor?: string;
    title: string;
    status: OrderStatusEnum;
    xs?: number;
}

interface IQsrTableViewActionsProps {
    data: IQsrOrder | null;
    restaurant: IRestaurant | null;
    onDone: (status: OrderStatusEnum, ticketId: string, refId: string) => void;
    onPrint: (id: string) => void;
}

export function QsrTableViewActions({ data, restaurant, onDone, onPrint }: IQsrTableViewActionsProps) {
    const { t } = useTranslation('common');
    const qsrOrdersService = QsrOrdersService.getInstance();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const id = data?.id || '';
    const ticketId = data?.ticketId || '';
    const [ticketIdtextInputValue, setTicketIdtextInputValue] = useState('');
    const [textInputToggle, setTextInputToggle] = useState(false);
    const hasQlubPosSection = ['qlub'].includes(restaurant?.posAccess?.posVendor.pos_vendor_name || '');

    const setOrderStatus = (status: OrderStatusEnum, hasReason?: boolean, reasonDetails?: IReasonDetail) => {
        const { reason_id, reason_message } = reasonDetails || {};
        if (loading) {
            return;
        }

        if (status === OrderStatusEnum.Declined && !hasReason) {
            setOpen(true);
            return;
        }

        setLoading(true);
        qsrOrdersService
            .setQsrOrderStatus({
                restaurantId: restaurant?.id || '',
                orderId: id,
                status,
                ticketId: ticketIdtextInputValue || ticketId || undefined,
                reason_id,
                reason_message,
            })
            .then(() => {
                enqueueSnackbar(t('Order status updated successfully!'), {
                    variant: 'success',
                });
                onDone(status, ticketIdtextInputValue || ticketId, data?.refId || '');
                setOpen(false);
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || t('Something went wrong!'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
                setTicketIdtextInputValue('');
                setTextInputToggle(false);
            });
    };

    const orderStatusHandler = (status: OrderStatusEnum) => {
        setOrderStatus(status);
    };

    const items = useMemo<IAction[]>(() => {
        if (!data || !restaurant) {
            return [];
        }

        const getStatus = (): IAction[] => {
            switch (data?.orderStatus?.name) {
                default:
                case OrderStatusEnum.Pending:
                    return [
                        {
                            id: 'accept',
                            title: t('Accept'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Approved,
                            xs: !data.ticketId ? 6 : 12,
                        },
                        ...(!data.ticketId
                            ? [
                                  {
                                      id: 'accept-ticket',
                                      title: t('with Ticket'),
                                      color: '#fff',
                                      bgColor: '#1C8659',
                                      secondaryBgColor: '#09693d',
                                      status: OrderStatusEnum.Approved,
                                      xs: 6,
                                  },
                              ]
                            : []),
                        {
                            id: 'decline',
                            title: t('Decline'),
                            color: '#fff',
                            bgColor: '#FF0000',
                            secondaryBgColor: '#ce0000',
                            status: OrderStatusEnum.Declined,
                            xs: 12,
                        },
                    ];
                case OrderStatusEnum.Approved:
                    return [
                        {
                            id: 'close',
                            title: t('Done'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Closed,
                            xs: !data.ticketId ? 6 : 12,
                        },
                        ...(!data.ticketId
                            ? [
                                  {
                                      id: 'close-ticket',
                                      title: t('with Ticket'),
                                      color: '#fff',
                                      bgColor: '#1C8659',
                                      secondaryBgColor: '#09693d',
                                      status: OrderStatusEnum.Closed,
                                      xs: 6,
                                  },
                              ]
                            : []),
                        {
                            id: 'decline',
                            title: t('Decline'),
                            color: '#fff',
                            bgColor: '#FF0000',
                            secondaryBgColor: '#ce0000',
                            status: OrderStatusEnum.Declined,
                            xs: 12,
                        },
                    ];
                case OrderStatusEnum.Declined:
                    if (data?.scheduledAt) {
                        return [];
                    }
                    return [
                        {
                            id: 'accept',
                            title: t('Accept'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Approved,
                            xs: 12,
                        },
                    ];
                case OrderStatusEnum.Scheduled:
                    return [
                        {
                            id: 'decline',
                            title: t('Decline'),
                            color: '#fff',
                            bgColor: '#FF0000',
                            secondaryBgColor: '#ce0000',
                            status: OrderStatusEnum.Declined,
                            xs: 12,
                        },
                    ];
                case OrderStatusEnum.Active:
                case OrderStatusEnum.Closed:
                    return [];
            }
        };
        if (!restaurant?.order_config?.vendorQsrCardViewActions?.length || hasQlubPosSection) {
            return getStatus();
        }
        return getStatus().filter((o) => restaurant?.order_config?.vendorQsrCardViewActions?.includes(o.status));
    }, [JSON.stringify(data), restaurant]);

    const triggerPrint = async () => {
        const res = await qsrOrdersService.setPrintTrigger({
            restaurantId: restaurant?.id || '',
            orderId: id,
        });
        if (res.statusCode === 200) {
            enqueueSnackbar(t('Print triggered successfully!'), {
                variant: 'success',
            });
            return;
        }
        enqueueSnackbar(t('Something went wrong!'), {
            variant: 'error',
        });
    };

    const PrintComponent = useMemo(() => {
        return (
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                        triggerPrint();
                        onPrint(id);
                    }}
                >
                    {t('Print')}
                </Button>
            </Grid>
        );
    }, [data]);

    if (items.length === 0) {
        return (
            <Grid container spacing={1} sx={{ marginTop: '2px' }}>
                {PrintComponent}
            </Grid>
        );
    }

    const handleButtonClick = (item: IAction) => {
        if (item.id.includes('ticket')) {
            setTextInputToggle(true);
        } else {
            orderStatusHandler(item.status);
        }
    };

    const handleCheckButton = (item: any) => {
        orderStatusHandler(item.status);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={1} sx={{ marginTop: '2px' }}>
            <QsrReasonModal open={open} onClose={handleClose} onDone={setOrderStatus} />
            {items.map((item) => {
                return (
                    <Grid item xs={item.xs}>
                        {item.id.includes('ticket') && textInputToggle ? (
                            <TextField
                                size="small"
                                variant="outlined"
                                label={t('Ticket ID')}
                                value={ticketIdtextInputValue}
                                onChange={(e) => setTicketIdtextInputValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {ticketIdtextInputValue ? (
                                                <>
                                                    <IconButton
                                                        onClick={() => {
                                                            setTicketIdtextInputValue('');
                                                        }}
                                                    >
                                                        <Close
                                                            sx={{
                                                                color: '#7D00D4',
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleCheckButton(item);
                                                        }}
                                                    >
                                                        <CheckRounded color="primary" />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <IconButton
                                                    onClick={() => {
                                                        setTicketIdtextInputValue('');
                                                        setTextInputToggle(false);
                                                    }}
                                                >
                                                    <Close
                                                        sx={{
                                                            color: '#7D00D4',
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        ) : (
                            <PrimaryButton
                                styles={{
                                    color: item.color,
                                    ...(item.bgColor ? { backgroundColor: item.bgColor } : {}),
                                    ...(item.secondaryBgColor
                                        ? {
                                              '&:hover': {
                                                  backgroundColor: item.secondaryBgColor,
                                              },
                                          }
                                        : {}),
                                }}
                                onClick={() => handleButtonClick(item)}
                                text={item.title}
                                fullWidth
                                disabled={loading}
                            />
                        )}
                    </Grid>
                );
            })}
            {PrintComponent}
        </Grid>
    );
}
